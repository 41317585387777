import React, { useEffect } from "react";
import {
  FloatingHelper,
  ToggleSwitch,
  FormField,
  EmptyState,
  CustomModalLayout,
  TextButton,
  Heading,
  Modal,
  MessageModalLayout,
  Text,
  Card,
  FeatureList,
  Box,
  Page,
  InputArea,
  Button,
  Cell,
  Layout,
  Input,
  WixDesignSystemProvider,
  Image,
  PageSection,
  Badge,
  MarketingLayout,
  AnnouncementModalLayout,
  Tooltip,
  PulseAnimation,
  Thumbnail,
  MarketingPageLayoutContent,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import * as Icons from "@wix/wix-ui-icons-common";
import "./App.css";
import { ReactTagManager } from "react-gtm-ts";
import * as BC from "@certifiedcode/base-components";
import Visa from "./IconsSource/visa.svg";
import ApplePay from "./IconsSource/applepay.svg";
import GooglePay from "./IconsSource/googlepay.svg";
import MasterCard from "./IconsSource/mastercard.svg";
import Amex from "./IconsSource/amex.svg";
import Discover from "./IconsSource/discover.svg";
import JCB from "./IconsSource/jcb.svg";
import DinersClub from "./IconsSource/diners_club.svg";
import UnionPay from "./IconsSource/union_pay.svg";
import Maestro from "./IconsSource/maestro.svg";
import { useTranslation } from "react-i18next";
const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  const { t } = useTranslation()
  ReactTagManager.init(tagManagerArgs);
  const [isHighlighted, setIsHighlighted] = React.useState(false);
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isOpening, setIsOpening] = React.useState(false);
  const [shownSettingsModal, setShownSettingsModal] = React.useState(false);
  const [key, setKey] = React.useState("");
  const [isBusinessAppModalOpened, setIsBusinessAppModalOpened] =
    React.useState(false);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
    getSettings();
  }, []);

  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=85b24815-cd82-4436-b982-16ad4486a418&redirectUrl=https://certifiedcode.wixsite.com/square-pay-button/_functions/@certifiedcode/base-backend/auth`;
  }

  const BASE_URL = `https://certifiedcode.wixsite.com/square-pay-button/_functions`;

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
      })
      .catch(() => {});
  }

  function setSettings() {
    // if (isUpgraded) {
    if (!open) {
      if (key === "") {
        setShownSettingsModal(true);
        return;
      }
    }
    const account =
      !open === true
        ? {
            key,
          }
        : {};
    setIsOpening(true);
    fetch(BASE_URL + "/settings", {
      method: "POST",
      headers: {
        Authorization: instance || "",
      },
      body: JSON.stringify({
        isEnabled: !open,
        ...account,
      }),
    })
      .then(() => {
        setShownSettingsModal(false);
        setIsOpening(false);
        setOpen(!open);
        setKey("");
      })
      .catch(() => {});
    // } else {
    //   setIsUpgradeModalOpen(true)
    // }
  }

  function getSettings() {
    setIsOpening(true);
    fetch(BASE_URL + "/settings", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setIsOpening(false);
        setOpen(data.isEnabled);
      })
      .catch(() => {});
  }

  const isPremiumApp = true;

  const renderThumbnail = ({ title, subtitle, id }: any) => (
    <Thumbnail
      onClick={() => {
        window.Paddle.Checkout.open({
          product: id,
          email: (instanceData as any)?.site?.ownerInfo?.email,
          allowQuantity: false,
        });
      }}
    >
      <Box padding="18px">
        <Box gap="12px" verticalAlign="middle">
          <Icons.Premium />
          <Box direction="vertical">
            <Text size="medium" weight="bold">
              {title}
            </Text>
            <Box>
              <Text size="small" secondary>
                {subtitle}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Thumbnail>
  );

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height="100vh">
        <Page.Header
          title={
            <Box gap={"SP2"} alignItems="middle">
              <Heading>{t('square-title')}</Heading>
            </Box>
          }
          actionsBar={
            <Box gap={"SP2"} direction="horizontal">
              {isUpgraded && (
                <Button
                  prefixIcon={<Icons.Reviews />}
                  skin="inverted"
                  as="a"
                  href="https://bridget.reviews.certifiedcode.us/cacc7ff0-c784-4d86-a09b-c40ee94fd34a"
                  target="_blank"
                >
                  {t('review-button')}
                </Button>
              )}
              <Button
                skin="premium"
                prefixIcon={<Icons.PremiumFilled />}
                onClick={() => setIsUpgradeModalOpen(true)}
              >
                {isUpgraded ? t('manage-plan') : t('accept-button')}
              </Button>
            </Box>
          }
        />
        <Page.Content>
          <Modal
            isOpen={isBusinessAppModalOpened}
            onRequestClose={() => setIsBusinessAppModalOpened(false)}
            shouldCloseOnOverlayClick
            screen="desktop"
          >
            <CustomModalLayout
              onCloseButtonClick={() => {
                setIsBusinessAppModalOpened(false);
              }}
              title={t('advanced-title')}
              content={
                <Card>
                  <MarketingPageLayoutContent
                    title={t('wix-title')}
                    content={
                      <Text>
                        <ul>
                          <li>
                            {t('wix-line-1')}
                          </li>
                          <li>{t('wix-line-2')}</li>
                          <li>{t('wix-line-3')}</li>
                          <Box
                            alignContent="center"
                            gap="SP1"
                            direction="horizontal"
                          >
                            <li>{t('wix-line-4')}</li>
                            <Badge size="tiny" skin="warningLight">
                              {t('new-badge')}
                            </Badge>
                          </Box>
                        </ul>
                      </Text>
                    }
                    actions={
                      <Layout>
                        <Cell>
                          <Layout cols={1} gap="12px">
                            <Text size="small" weight="bold">
                              {t('plan-heading')}
                            </Text>
                            {renderThumbnail({
                              title: t('life-title'),
                              subtitle:
                                t('life-subtitle'),
                              id: 861018,
                            })}
                            {renderThumbnail({
                              title: t('billing-title'),
                              subtitle:
                               t('billing-subtitle'),
                              id: 861017,
                            })}
                          </Layout>
                        </Cell>
                        <Cell>
                          <Text size="tiny">
                           {t('plan-text')} {" "}
                            <a
                              href="https://www.certifiedcode.us/legal/terms-of-services"
                              target="_blank"
                            >
                              {t('term-link')}
                            </a>
                          </Text>
                        </Cell>
                        <Cell></Cell>
                      </Layout>
                    }
                  />
                </Card>
              }
            />
          </Modal>
          <Modal
            isOpen={shownSettingsModal}
            onRequestClose={() => setShownSettingsModal(false)}
            shouldCloseOnOverlayClick
            screen="desktop"
          >
            <CustomModalLayout
              primaryButtonText={t('primaryButtonText')}
              secondaryButtonText={t('secondaryButtonText')}
              onCloseButtonClick={() => setShownSettingsModal(false)}
              secondaryButtonOnClick={() => setShownSettingsModal(false)}
              primaryButtonOnClick={() => setSettings()}
              removeContentPadding
              title={t('connect-square')}
              content={
                <Page>
                  <Page.Content>
                    <Box marginTop={5} display="block">
                      <Layout>
                        <Cell span={12}>
                          <Box direction="vertical" gap="SP2">
                            <FormField
                              status={
                                // only starting with EA is valid
                                key.startsWith("EA") || key.length === 0
                                  ? undefined
                                  : "warning"
                              }
                              statusMessage={
                                // only starting with EA is valid
                                key.startsWith("EA") || key.length === 0
                                  ? ""
                                  : t('status-square')
                              }
                              label={t('token-label')}
                              suffix={
                                <TextButton
                                  size="small"
                                  as="a"
                                  href="https://support.certifiedcode.us/en/articles/8379264-square-payments-setup"
                                  target="_blank"
                                  prefixIcon={<Icons.ExternalLinkSmall />}
                                >
                                  {t('find-button')}
                                </TextButton>
                              }
                            >
                              <Input
                                clearButton
                                value={key}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setKey(e.target.value)}
                              />
                            </FormField>
                          </Box>
                        </Cell>
                      </Layout>
                    </Box>
                  </Page.Content>
                </Page>
              }
            />
          </Modal>
          <Modal
            isOpen={isUpgradeModalOpen}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => setIsUpgradeModalOpen(false)}
          >
            <AnnouncementModalLayout
              theme="premium"
              title={t('payment-title')}
              primaryButtonText={t('upgrade-button')}
              // linkText="Learn more"
              primaryButtonOnClick={() => {
                window.open(
                  `https://www.wix.com/apps/upgrade/${"85b24815-cd82-4436-b982-16ad4486a418"}?appInstanceId=${
                    (instanceData as any)["instance"]["instanceId"]
                  }`
                );
              }}
              // linkOnClick={() => { window.open('https://support.certifiedcode.us/en/articles/7953534-getting-started-ccavenue-pay-button#h_b92f8de467') }}
              onCloseButtonClick={() => setIsUpgradeModalOpen(false)}
            >
              <Text>
                {t('payment-text')}
              </Text>
            </AnnouncementModalLayout>
          </Modal>
          <Modal isOpen={isModalOpen} screen="desktop">
            <MessageModalLayout
              primaryButtonText="Refresh"
              primaryButtonOnClick={() => window.location.reload()}
              title={t('error-title')}
              content={
                <Text>{t('refresh-title')}</Text>
              }
            />
          </Modal>
          <Layout>
            {isUpgraded && (
              <Cell span={12}>
                <Card>
                  <MarketingLayout
                    size="tiny"
                    title={t('looking-title')}
                    description={t('looking-description')}
                    actions={
                      <Box gap="SP1">
                        {/* <Button size="small" suffixIcon={<Icons.PlaySmall />} onClick={()=>{
                      // setViewDemo(true);
                    }}>Watch Demo</Button> */}
                        <Button
                          size="small"
                          suffixIcon={<Icons.ExternalLinkSmall />}
                          onClick={() => {
                            setIsBusinessAppModalOpened(true);
                          }}
                        >
                          {t('pricing-button')}
                        </Button>
                      </Box>
                    }
                  />
                </Card>
              </Cell>
            )}
            <Cell span={12}>
              <Card>
                <Card.Header
                  title={
                    <Box gap={"SP2"}>
                      <Heading size="medium" as="h2">
                        {t('online-heading')}
                      </Heading>
                      {isUpgraded ? (
                        <Badge size="tiny" skin={"success"}>
                          {t('ready-badge')}
                        </Badge>
                      ) : (
                        <FloatingHelper
                          target={
                            <Badge size="tiny" skin={"danger"}>
                              {t('limited-badge')}
                            </Badge>
                          }
                          content={
                            <FloatingHelper.Content
                              title={t('helper-title')}
                              body={t('helper-body')}
                            />
                          }
                          placement="right"
                        />
                      )}
                    </Box>
                  }
                  suffix={
                    <Button
                      skin={open ? "destructive" : "dark"}
                      onClick={() => {
                        setSettings();
                      }}
                      disabled={isOpening}
                    >
                      {open ? t('disconnect-button') : t('connect-button')}
                    </Button>
                  }
                />
                {/* <Card.Subheader title={<Text size='small'>Pay Button can be placed everywhere on your site via Editor, and supports custom amount or pre-defined amount payment. <b>Note: Pay Button does not integrate with Wix eCommerce.</b></Text>} suffix={
                  <Button skin='light' size="small" onClick={() => { setIsHighlighted(true) }}>Explore advanced integration</Button>
                } /> */}
                <Card.Subheader
                  title={
                    <Box direction="vertical" gap="SP2">
                      <Box gap={"SP1"} verticalAlign="middle">
                        <Text size="small">{t('payment-method')} </Text>
                        <Image borderRadius={0} src={ApplePay} width="36px" />
                        <Image borderRadius={0} src={GooglePay} width="36px" />
                        <Image borderRadius={0} src={Visa} width="36px" />
                        <Image borderRadius={0} src={MasterCard} width="36px" />
                        <Image borderRadius={0} src={Amex} width="36px" />
                        <Image borderRadius={0} src={Discover} width="36px" />
                        <Image borderRadius={0} src={JCB} width="36px" />
                        <Image borderRadius={0} src={DinersClub} width="36px" />
                        <Image borderRadius={0} src={UnionPay} width="36px" />
                        <Image borderRadius={0} src={Maestro} width="36px" />
                        <Text size="small" skin="disabled">
                         {t('method-list')}
                        </Text>
                      </Box>
                    </Box>
                  }
                />
                <Card.Content>
                  {open ? (
                    <EmptyState
                      theme="section"
                      title={t('live-title')}
                      subtitle={t('live-subtitle')}
                    >
                      <TextButton
                        prefixIcon={<Icons.ExternalLink />}
                        as={"a"}
                        href={
                          (instanceData as any)["site"]
                            ? (instanceData as any)["site"]["url"]
                            : ""
                        }
                        target={"_blank"}
                      >
                        {t('live-button')}
                      </TextButton>
                    </EmptyState>
                  ) : (
                    <></>
                  )}
                </Card.Content>
              </Card>
            </Cell>
            <Cell>
              <PageSection title={t('suggested-title')} showDivider />
            </Cell>
            {/* <Cell span={12}>
              <Card>
                <PulseAnimation active={isHighlighted} color="B10" borderRadius="18px" fluid={true}>
                  <MarketingLayout
                    title="Accept Online Payments"
                    description="With Certified Code Business Solutions, you can accept online payments from your customers via Wix Stores, Bookings and more."
                    actions={<Box direction="horizontal" gap="SP1">
                      <Button size="small" skin="premium" as='a' href='https://certifiedcode.in/pricing' target='_blank'>See Pricing</Button>
                      <Button size="small" skin="inverted" onClick={() => { window.Intercom("showNewMessages", "I would like to accept payments with Square on Wix Stores and other Wix apps.") }}>Talk with sales</Button>
                    </Box>}
                    size="tiny"
                    badge={<Badge size="small">ADVANCED INTEGRATION</Badge>}
                    image={
                      <Box width="100%" align="right">
                        <Image
                          width="120px"
                          src="https://www.wix-style-react.com/storybook/generic_upgrade.svg"
                          transparent
                        />
                      </Box>
                    }
                  />
                </PulseAnimation>
              </Card>
            </Cell> */}
            <Cell span={12}>
              <Card>
                <MarketingLayout
                  title={t('contact-title')}
                  description={t('contact-description')}
                  actions={
                    <Button
                      size="small"
                      onClick={() => {
                        window.Intercom(
                          "showNewMessages",
                          t('contact-msg')
                        );
                      }}
                    >
                      {t('chat-button')}
                    </Button>
                  }
                  size="tiny"
                  hiddenBadge
                  alignItems="stretch"
                  image={
                    <Box width="100%" align="right">
                      <Image
                        width="120px"
                        src="https://www.wix-style-react.com/storybook/PromotionalBookingsUpgrade.svg"
                        transparent
                      />
                    </Box>
                  }
                />
              </Card>
            </Cell>
            <Cell span={12}>
              <BC.Recommends appId="85b24815-cd82-4436-b982-16ad4486a418" />
            </Cell>
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
